import { ChangeEvent, useEffect, useState } from "react";
import { estimateCollectorRequest } from "../../../../../models/request/customerEstimate-request";
import { ArrowRight } from "../../../../assets";
import {
  useCreateFormRequestMutation,
  useGetAuthTokenMutation,
  useUploadMediaMutation,
} from "../../../../../services/customerEstimate-service";
import toast from "react-hot-toast";
import QuoteUserDetails from "./QuoteUserDetails";
import { useNavigate } from "react-router-dom";

const QuoteForms = ({ fields, request }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<estimateCollectorRequest>({
    planCategoryName: request.category,
    planName: request.plan,
    channel: "WEB",
    customerRequestDetails: [],
    requestor: {
      name: "",
      phone: "",
      email: "",
    },
  });

  const [formRequest] = useCreateFormRequestMutation();
  const [uploadMedia] = useUploadMediaMutation();
  const [getAuthToken] = useGetAuthTokenMutation();

  useEffect(() => {
    const fetchAuthToken = async () => {
      try {
        const authTokenResponse = await getAuthToken().unwrap();
        if (authTokenResponse) {
          sessionStorage.setItem("authToken", authTokenResponse);

        } else {
          throw new Error("Failed to retrieve authentication token.");
        }
      } catch (error) {
        console.error("Error fetching auth token on load:", error);
        toast.error(
          "Failed to retrieve authentication token. Please try again."
        );
      }
    };

    fetchAuthToken();
  }, [getAuthToken]);

  const handleInputChange = async (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    label: string
  ) => {
    const { name, value, type, files } = e.target as HTMLInputElement;

    let answerDataType: "string" | "double" | "binary" | "date";

    // Determine the answerDataType based on the input type
    switch (type) {
      case "number":
        answerDataType = "double";
        break;
      case "date":
        answerDataType = "date";
        break;
      case "file":
        answerDataType = "binary";
        break;
      default:
        answerDataType = "string";
    }

    if (type === "file") {
      const file = files && files[0];
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);

          // Upload the file and get the response
          const response = await uploadMedia(formData).unwrap();

          const mediaId = response.data?.id || null;

          // Update formData with the uploaded file details
          setFormData((prevFormData) => ({
            ...prevFormData,
            customerRequestDetails: [
              ...prevFormData.customerRequestDetails.filter(
                (item) => item.question !== label
              ),
              {
                question: label,
                answer: file.name,
                answerDataType: "binary",
                hasAttachment: true,
                mediaId,
              },
            ],
          }));
        } catch (error) {
          console.error("File upload error:", error);
          toast.error("Failed to upload file. Please try again.");
        }
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customerRequestDetails: [
          ...prevFormData.customerRequestDetails.filter(
            (item) => item.question !== label
          ),
          {
            question: label,
            answer: value,
            answerDataType, // Use the determined answerDataType
            hasAttachment: false,
            mediaId: null,
          },
        ],
      }));
    }
  };

  const updateRequestor = (requestorData) => {

    setFormData((prevFormData) => ({
      ...prevFormData,
      requestor: requestorData,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsOpen(true); // Open modal for requester details
  };

  const handleFinalSubmit = async () => {
    try {
      const response = await formRequest(formData).unwrap();
  
      const { code, message, data } = response;
      if (code === "00") {
        const id = data?.id;
        toast.success(message);
        navigate(`/insurance-quote/success/${id}`);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  return (
    <div className="lg:col-span-2 my-auto">
      <form onSubmit={handleFormSubmit}>
        <div className="grid gap-5 lg:grid-cols-2 my-4">
          {fields.map((field, index) => (
            <div className="space-y-2" key={index}>
              <label
                htmlFor={field.name}
                className="inline-block text-sm font-medium text-[#003862] mt-2.5"
              >
                {field.label}
              </label>
              {field.type === "text" && (
                <input
                  id={field.name}
                  type="text"
                  name={field.name}
                  required={field.required}
                  onChange={(e) => handleInputChange(e, field.label)}
                  className="py-4 px-3 block w-full border-2 border-gray-200 bg-gray-50 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "email" && (
                <input
                  id={field.name}
                  type="email"
                  name={field.name}
                  required={field.required}
                  onChange={(e) => handleInputChange(e, field.label)}
                  className="py-4 px-3 block w-full border-2 border-gray-200 bg-gray-50 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "date" && (
                <input
                  id={field.name}
                  type="date"
                  name={field.name}
                  required={field.required}
                  onChange={(e) => handleInputChange(e, field.label)}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm rounded-lg bg-gray-50 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "number" && (
                <input
                  id={field.name}
                  type="number"
                  name={field.name}
                  required={field.required}
                  onChange={(e) => handleInputChange(e, field.label)}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "file" && (
                <input
                  id={field.name}
                  type="file"
                  name={field.name}
                  required={field.required}
                  onChange={(e) => handleInputChange(e, field.label)}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "select" && field.options && (
                <select
                  id={field.name}
                  name={field.name}
                  required={field.required}
                  onChange={(e) => handleInputChange(e, field.label)}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                >
                  <option selected disabled value="">
                    Select an option
                  </option>
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option.key}>
                      {option.value}
                    </option>
                  ))}
                </select>
              )}
              {field.type === "textarea" && (
                <textarea
                  id={field.name}
                  name={field.name}
                  required={field.required}
                  onChange={(e) => handleInputChange(e, field.label)}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
            </div>
          ))}
        </div>
        <button
          type="submit"
          className=" flex underline group justify-end w-full font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200"
        >
          Next
          <span
            aria-hidden="true"
            className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
          >
            <img src={ArrowRight} alt="" />
          </span>
        </button>
      </form>
      <QuoteUserDetails
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        requestor={formData.requestor}
        updateRequestor={(data) => {
      
          updateRequestor(data);
        }}
        onSubmit={handleFinalSubmit}
      />
    </div>
  );
};

export default QuoteForms;
