import { useParams } from "react-router-dom";
import { useGetFormRequestByIdQuery } from "../../../../../services/customerEstimate-service";
import Loader from "../../../../components/Loader";

const QuoteSuccessful = () => {
  const { id } = useParams();
  const quoteId = Number(id);

  const { data: response, isLoading } = useGetFormRequestByIdQuery(quoteId);
  const quote = response?.data;

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <section className=" bg-white py-36">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-start lg:items-center">
            <svg
              className="flex-shrink-0 text-green-500 w-11 h-11"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            <div className="ml-4">
              <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                Quote Successful!
              </h1>
              <p className="mt-2 text-sm font-normal text-gray-600">
                Hello, {quote?.requestor?.name}. Thank you for contacting us.
                Your request for a quotation on {quote?.planName} has been
                received with the reference code{" "}
                {quote?.requestor?.referenceCode}. You can reach us over
                WhatsApp at +233 553 666 636 or dial *718*7# on all networks.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 mt-8 lg:grid-cols-5 lg:items-start xl:grid-cols-6 gap-y-10 lg:gap-x-12 xl:gap-x-16">
            <div className="pt-6  lg:col-span-3 xl:col-span-4">
              <h2 className="text-sm font-bold text-gray-500">
                Request Details
              </h2>

              <div className="flow-root mt-4">
                <dl className="divide-y divide-gray-200">
                  {quote?.customerRequestDetail?.map((item) => (
                    <div
                      key={item.id}
                      className="bg-white  py-4 sm:grid sm:grid-cols-2 sm:gap-2"
                    >
                      <dt className="text-sm font-medium text-gray-500">
                        {item.question}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900  sm:mt-0">
                        {item.answer}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>

            <div className="lg:col-span-2 lg:sticky lg:top-6">
              <div className="overflow-hidden rounded bg-[#003862]">
                <div className="px-4 py-6 sm:p-6 lg:p-8">
                  <div className="space-y-9">
                    <div>
                      <h3 className="text-sm font-bold text-gray-50">
                        Cutomer Details
                      </h3>
                      <p className="mt-4 text-sm font-medium text-gray-100">
                        {quote?.requestor?.name}
                      </p>
                      <p className="mt-3 text-sm font-medium text-gray-100">
                        {quote?.requestor?.email}
                      </p>
                      <p className="mt-3 text-sm font-medium text-gray-100">
                        {quote?.requestor?.phone}
                      </p>
                    </div>

                    <div>
                      <h3 className="text-sm font-bold text-gray-50">
                        Insurance Plan
                      </h3>
                      <p className="mt-4 text-sm font-medium text-gray-100">
                        {quote?.planCategory}
                      </p>
                      <p className="mt-3 text-sm font-medium text-gray-100">
                        {quote?.planName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default QuoteSuccessful;
