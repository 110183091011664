import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../core/app-constants";
import { BaseResponse } from "../models/response/base-response";
import { categoryResponse } from "../models/response/customerEstimate-response";
import queryString from "query-string";
import {
  estimateCollectorRequest,
  getCategoryParams,
} from "../models/request/customerEstimate-request";

export const customerEstimateService = createApi({
  reducerPath: "customerEstimateService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("authToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["CustomerEstimate"],
  endpoints: (build) => ({
    getCategory: build.query<
      BaseResponse<categoryResponse[]>,
      getCategoryParams
    >({
      query: (params) => ({
        url: `/customer-estimate-plan-category?${queryString.stringify(
          params
        )}`,
        method: "GET",
      }),
      providesTags: ["CustomerEstimate"],
    }),
    getPlan: build.query<BaseResponse<any[]>, getCategoryParams>({
      query: (params) => ({
        url: `/customer-estimate-plan?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["CustomerEstimate"],
    }),
    createFormRequest: build.mutation<
      BaseResponse<any>,
      estimateCollectorRequest
    >({
      query: (body) => ({
        url: "/estimate/collector",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["CustomerEstimate"],
    }),

    getFormRequestById: build.query<BaseResponse<any>, number>({
      query: (id) => ({
        url: `/estimate/collector/${id}`,
        method: "GET",
      }),
      providesTags: ["CustomerEstimate"],
    }),
    uploadMedia: build.mutation<BaseResponse<any>, FormData>({
      query: (body) => ({
        url: "/request-detail-media",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["CustomerEstimate"],
    }),
    getAuthToken: build.mutation<string, void>({
      query: () => ({
        url: "/auth/token",
        method: "POST",
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: ["CustomerEstimate"],
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useGetPlanQuery,
  useCreateFormRequestMutation,
  useUploadMediaMutation,
  useGetFormRequestByIdQuery,
  useGetAuthTokenMutation,
} = customerEstimateService;
